export enum BlogCollection {
  STORIES = 'stories',
  NEWS = 'news',
  PRESS_RELEASES = 'press_releases',
  TECH = 'tech',
  MEDIA = 'media'
}

export enum StoreId {
  APPLE_ARCADE = 'apple_arcade',
  APP_STORE = 'app_store',
  GOOGLE_PLAY = 'google_play',
  AMAZON_APPSTORE = 'amazon_appstore',
  HUAWEI_APPGALLERY = 'huawei_appgallery'
}
